$(document).ready(function() {
    $(".nav__left h3:not(.noactive)").click(function() {
        if ($(window).width() > 767) {
            if ($(this).hasClass('active')) {
                $(this).removeClass("active").siblings("ul").slideUp();
                $(this).siblings(".btn-list-half").css("display", "none");
            } else {
                $(this).addClass("active").siblings("ul").slideDown();
                $(this).siblings(".btn-list-half").css("display", "block");
            }

        }
    })

    $(".btn-list-half").on("click", function(e) {
        e.preventDefault();
        if ($(this).hasClass('btn--active')) {
            $(this).removeClass("btn--active").siblings("ul").find(".list__item--hidden").each(function() {
                $(this).slideUp();
            })
        } else {
            $(this).addClass("btn--active").siblings("ul").find(".list__item--hidden").each(function() {
                $(this).slideDown();
            })
        }
    });

    $(".nav-left__btn--mobile").click(function() {
        $(".nav-left-hideonMobile").slideToggle();
        $(this).find("span").toggleClass("active");
    })

    function isInViewport(element) {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.top - 300 <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }




    let number_format = function(number, decimals, dec_point, thousands_sep) {
        if (number.isNumeric) {
            var nstr = number.toString();
        } else {
            var nstr = number;
        }
        nstr += '';
        let x = nstr.split('.');
        let x1 = x[0];
        let x2 = x.length > 1 ? dec_point + x[1] : '';
        var rgx = /(\d+)(\d{3})/;

        while (rgx.test(x1))
            x1 = x1.replace(rgx, '$1' + thousands_sep + '$2');

        return x1 + x2;
    }

    function changePrice(price) {
        if (price || price == 0) {
            return number_format(price, 2, ',', ' ')
        }
    }

    function saveConfiguration() {
        let configurationsData = $("#form__nav-left input:not('.noserialize'), .product-list__option input, .product-list__option select").serialize();
        //let configurationsData = new FormData($("#personalization_details")[0]);
        let id = $(".personalization").data("konfiguracja");
        //window.history.pushState('page2', 'Title', configurationsData);
        window.location.hash = "" + configurationsData + "";

        if (configurationsData.length > 2) {
            $("head meta[name='robots']").attr("content", "noindex, nofollow");

        } else {
            $("head meta[name='robots']").attr("content", "index, follow, all");

        }

        //$("#personalization_details input[name='konfiguracjaHash']").val(configurationsData);       

        // $.post('/a/', {
        //     akcja: "konfiguracjaZapisz",
        //     konfiguracjaHash: id,
        //     konfiguracjaDane: configurationsData
        // });


    }

    $(".list__2col [name='typ[]']").each(function() {

        let value = $(this).val();
        let $this = $(this);
        $("[name='typ[]']").each(function() {

            if ($(this).val() == value) {
                $(this).addClass("thesame");
                $this.addClass("thesame");
            }
        })
    })

    $(".thesame").on("change", function() {
        let value = $(this).val();
        if ($(this).prop("checked")) {
            $("[name='typ[]'][value='" + value + "']").prop("checked", "checked");
        } else {
            $("[name='typ[]'][value='" + value + "']").prop("checked", false);
        }

    })


    function aktywujAromat(input) {

        if (input.prop("checked")) {
            $("#aktywujAromat-items input").removeClass("noserialize");
            $("#aktywujAromat-items label").removeClass("nopress");
            $("#aktywujAromat-items").parents(".inputs-group__wrapper").removeClass("noactive");
        } else {
            $("#aktywujAromat-items input").addClass("noserialize");
            $("#aktywujAromat-items label").addClass("nopress");
            $("#aktywujAromat-items").parents(".inputs-group__wrapper").addClass("noactive");
        }
    }


    function aktywujSmak(input) {
        if (input.prop("checked")) {
            $("#aktywujSmak-items input").removeClass("noserialize");
            $("#aktywujSmak-items label").removeClass("nopress");
            $("#aktywujSmak-items").find(".group__wrapper").removeClass("noactive");
        } else {
            $("#aktywujSmak-items input").addClass("noserialize");
            $("#aktywujSmak-items label").addClass("nopress");
            $("#aktywujSmak-items").find(".group__wrapper").addClass("noactive");
        }
    }


    if ($("#form__nav-left").length) {



        function createElementList(product) {

            if (product.hasOwnProperty("id")) {

                let kindColor = '';
                if (product.kolor == "1") { kindColor = ' class="plk--white"' };
                if (product.kolor == "2") { kindColor = ' class="plk--red"' };
                if (product.kolor == "3") { kindColor = ' class="plk--pink"' };

                let kindColorBox = '';
                if (product.kolorNazwa) {
                    kindColorBox = '<span' + kindColor + '>' + product.kolorNazwa + '</span>'
                }
                let rodzaj = '';
                if (product.rodzaj) {
                    rodzaj = '<span class="plk--grey">' + product.rodzaj + '</span>'
                }
                let kind = ''
                if (product.rodzaj || product.kolorNazwa) {
                    kind = '<div class="p-list__kind">' + kindColorBox + rodzaj + '</div>'; //różowe, czerwone
                }

                let magazyn = "";

                let price = "";
                let promo1 = "";
                if (product.cenaPoprzednia) {
                    price = '<div class="p-list__sale">' + changePrice((product.cena).replace(".", ",")) + '<small> zł</small><span>' + changePrice((product.cenaPoprzednia).replace(".", ",")) + ' zł</span></div>'
                    if (product.procent) {
                        promo1 = '<span class="p-list__info p-list__info--red">Promocja <span>-' + product.procent + '%</span></span>'
                    }
                } else {
                    price = '<div class="p-list__price">' + changePrice((product.cena).replace(".", ",")) + '<small> zł</small></div>'
                }


                let ulubione = "";
                if (product.ulubione == 1) {
                    ulubione = ' is--active '
                }

                if (product.dostepne == "0") {
                    magazyn = '<div class="text-center"><span class="text--main bold">Chwilowo niedostępny</span><button class="btn--black m-t-10 btn--getInfo btn--light btn--modal btn-modal-js" data-modal="dialog-powiadom">Powiadom</button><button class="constant-cart ' + ulubione + ' "><span>Do ulubionych</span></button></div>'

                } else {
                    magazyn = '<div class="available">Dostępny, wysyłka w 24h</div><div class="btn btn--form"><input type="number" value="1" min="1" max="200"><button>Do koszyka</button></div><button class="constant-cart ' + ulubione + ' "><span>Do ulubionych</span></button></div>'
                }

                let nazwaProduktu = ''
                if (product.nazwa) { nazwaProduktu = '<h2><a href="' + product.url + '">' + product.nazwa + '<span class="speech-bubble"> ' + product.jakoscNazwa + '<span>' + product.jakoscOpis + '</span></span></a></h2>' }

                let producent = '';
                if (product.producentNazwa) { producent = '<div class="p-list__pr">Producent: <a href="' + product.producentUrl + '">' + product.producentNazwa + '</a></div>'; }

                let star = '';
                if (product.listaKomentarzySredniaPx != "0") { star = '<div class="p-list__star"><span class="star m-r-5"><span style="width:' + product.listaKomentarzySredniaPx + '%"></span></span><strong>' + product.listaKomentarzySrednia + '</strong></div>'; }


                let win = ''
                if (product.medaleIle != "0") { win = '<div class="p-list__win" style="width:' + product.medaleIle + 'px"></div>'; }

                let parametrySW = '';
                if (product.medaleIle != "0" || product.listaKomentarzySredniaPx != "0") { parametrySW = '<div class="p-list__sw">' + star + win + '</div>' }

                let par1 = '';
                let par2 = '';
                let par3 = '';
                letpar4 = '';
                if (product.zawartoscAlkoholu) par1 = '<li><span>Zawartość alkoholu: </span><strong>' + product.zawartoscAlkoholu + '</strong></li>';
                if (product.rocznik) par2 = '<li><span>Rocznik: </span><strong>' + product.rocznik + '</strong></li>';
                if (product.pojemnosc) par3 = '<li><span>Pojemność: </span><strong>' + product.pojemnosc + '</strong></li>';
                if (product.regionNazwa) par4 = '<li><span>Region: </span><strong>' + product.regionNazwa + '</strong></li>';

                let parametry = ''
                if (product.zawartoscAlkoholu || product.rocznik || product.pojemnosc || product.regionNazwa) parametry = '<ul class="p-list__data">' + par1 + par2 + par3 + par4 + '</ul>';

                let opis = '';
                if (product.opis) {
                    let description = product.opis;
                    let shortText = jQuery.trim(description).substring(0, 220)
                        .trim(this) + "...";
                    opis = '<p>' + shortText + '<a class="read-more" href="' + product.url + '" >czytaj więcej</a></p>';
                }

                let element = $('<div class="product-list__item product__parent" data-id=' + product.id + '><div class="p-list__left"><figure class="product__figure loader"><a href="' + product.url + '"rel="nofollow"><img  src="' + product.zdjecie + '" alt="' + product.nazwa + '"></a></figure><div class="p-list__des">' + kind + nazwaProduktu + producent + parametrySW + parametry + opis + '</div></div><div class="p-list__right">' + promo1 + '<div class="p-list__bg">' + price + magazyn + '</div></div></div>');

                $(".product-list__wrapper").append(element);
            }

        }


        let products = [];
        let counterLoadProduct = 1;
        const classContainer = $(".products-list__wrapper").attr("class");

        function filterListProduct() {
            counterLoadProduct = 1;
            let form = $("#form__nav-left");
            let formInputs = form.find("input:checked");
            if ($("#form__nav-left").hasClass("beforeCheckedOptions")) {
                $("#form__nav-left").removeClass("beforeCheckedOptions");
            } else {
                saveConfiguration();
            }

            let parametry = $("#form__nav-left input:not('.noserialize'), .product-list__option input, .product-list__option select").serializeArray();

            $(".product-list__wrapper  .pagination").remove();
   
            $.ajax({
                type: "POST",
                url: "/a/",
                data: parametry,
            }).done(function(data) {
             
                if (typeof data == 'object') {
       
                    $(".products-list__wrapper").attr("class", classContainer);
                    $(".product-list__wrapper").html("<span style='height:500px;' class='loader'></span>");
                    $(".product-list__wrapper").html("");
                    products = data;

                    if (products.length > 0) {



                        $.each(products, function(i = 0, product) {

                            if (i < 10) {
                                createElementList(product)
                            }

                        })


                        $('.loader img').each(function() {
                            $(this).on('load', function() {
                                $(this).parents("figure").removeClass("loader");
                            });
                        });
                        $(".product-list__count span").html(products.length);


                        if (products.length > 10) {                         
                            $(document).on('scroll', function() {
                                if (isInViewport($("footer")[0]) && !$(".products-list__wrapper").hasClass("is-download-" + counterLoadProduct + "")) {

                                    $(".products-list__wrapper").addClass("is-download-" + counterLoadProduct + "");
                                    if (visibleFooter = 1) {
                                        counterLoadProductStart = (counterLoadProduct) * 10;
                                        counterLoadProductStop = (counterLoadProduct + 1) * 10;
                                        if (counterLoadProductStart < products.length) {
                                            counterLoadProduct++;
                                        } else {
                                            return;
                                        }

                                        $.each(products, function(i = 0, product) {
                                            if (i >= counterLoadProductStart && i < counterLoadProductStop) {
                                         
                                                createElementList(product);
                                            }
                                        })
                                        $('.loader img').each(function() {
                                            $(this).on('load', function() {
                                                $(this).parents("figure").removeClass("loader");
                                            });
                                        });
                                    }
                                }

                            });
                        }
                    } else {


                        $(".product-list__count span").html(products.length);
                        $(".product-list__wrapper").append('<div class="info--green" style="display:block; padding:50px 25px;">Brak produktów spełniających kryteria</div>');
                    }
                } else {
                    $(".product-list__wrapper").html('<div class="info--green" style="display:block; padding:50px 25px;">Zmień kryteria</div>');
                    $(".pagination").remove();
                }
            })
        }



        $("#form__nav-left input, .product-list__option input, .product-list__option select").on("change", function() {
            filterListProduct();
        });
        $("#form__nav-left").on("submit", function(e) {
            e.preventDefault();
            filterListProduct();
        });

        $("#form__nav-left button").on("click", function(e) {
            e.preventDefault();
            filterListProduct();
        });


        $(".input-select").on("keyup change", function() {
            let fraza = $(this).val();
            var exp = new RegExp(fraza, 'i');
            if (fraza.length > 0) {
                $(this).siblings(".btn-list-half").css("opacity", "0");
                $(this).siblings("ul").find("li label").each(function() {
                    var $self = $(this);
                    if (!exp.test($self.text())) {
                        $(this).parent("li").addClass("no-searched");
                        $(this).parent("li").removeClass("yes-searched");
                        $(this).siblings("input").prop("checked", false);
                    } else {
                        $(this).parent("li").removeClass("no-searched");
                        $(this).parent("li").addClass("yes-searched");
                        //$(this).siblings("input").prop("checked", "checked");
                    }
                })

            } else {
                $(this).siblings(".btn-list-half").css("opacity", "1");
                $(this).siblings("ul").find("li").each(function() {
                    $(this).removeClass("no-searched");
                    $(this).removeClass("yes-searched");
                    // $(this).find("input").prop("checked", false);
                })
            }
            filterListProduct();
        })




        let thisDataJSON;

        function fillInputFromAjax() {
            let thisData = window.location.href.substring(window.location.href.lastIndexOf('#') + 1).replace('#=', '');

            if (thisData.indexOf("akcja=produkty") >= 0) {
                $(".product-list__container .row").html("");
                $(".pagination").remove();

                const serializeToJSON = str =>
                    str.split('&')
                thisDataJSON = serializeToJSON(thisData);
                console.log(thisDataJSON);

                $(".form-checkbox input:checked, .form-radio input:checked").each(function() {
                    $(this).removeAttr("checked");
                })

                $.each(thisDataJSON, function(i = 0, input) {
                    input = input.replace('%5B%5D', '[]');

                    let name = input.substring(0, input.indexOf("="));
                    let value = input.substring(input.indexOf("=") + 1, input.length);

                    let $this = $(".products-list__wrapper input[value='" + value + "'][name='" + name + "']");

                    if (!$this.length) {
                        $this = $(".products-list__wrapper select[name='" + name + "'] option[value='" + value + "']");

                        if ($this.length) {
                            $this.attr('selected', 'selected');
                            return;
                        } else {
                            if (name == "wpiszRegion") {
                                $("[name='" + name + "']").val(value).change();
                                return;
                            } else {
                                if (name == "q") {
                                    $("[name='" + name + "']").val(value);
                                    return;
                                }
                                return;
                            }
                        }
                    }

                    if (i == thisDataJSON.length - 1) {
                        $this.siblings("label").click();
                    } else {
                        $this.prop("checked", "checked");
                    }

                    if ($this.parents("li").hasClass("list__item--hidden")) {
                        $this.parents("ul").siblings("button").click();
                    };
                    if ($this.parents("fieldset").find("h3").hasClass("active")) {} else {
                        $this.parents("fieldset").find("h3").click();
                    }

                    if ($this.attr("id") == "aktywujAromat") {
                        aktywujAromat($this);
                    }
                    if ($this.attr("id") == "aktywujSmak") {
                        aktywujSmak($this);
                    }

                })

                $(".nav__left [name=q]").val("");
                filterListProduct();

            } else {
                //filtrowanie tego, co juz zaznaczone, bez adresu url;
                filterListProduct();
            }
        }

        if ($(".product-list__wrapper").hasClass("product-list__wrapper-ajax")) {
            fillInputFromAjax();
        }

    }

})

$(".go-top").on("click", function(e) {
    // 1
    e.preventDefault();
    // 2
    const href = $(this).attr("href");
    // 3
    $("html, body").animate({ scrollTop: $(href).offset().top }, 800);
});